import React, { useEffect, useRef, useState } from "react"
import queryString from "query-string"
import LoadingBar from "react-top-loading-bar"

import Layout from "../components/layout"
import axios from "axios"
import Select from "react-select"
import { Formik } from "formik"
import { Form } from "react-bootstrap"
import * as Yup from "yup"
import { fetch } from "cross-fetch"
import countryJson from "../components/countries-states-cities"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "../../static/careers.css"
import { navigate } from "gatsby"
// import Loader from "react-loader"
import { ApolloClient, InMemoryCache, gql, HttpLink } from "@apollo/client"
import ReactHtmlParser from "react-html-parser"
import moment from "moment"

const PositionDetail = (props) => {
  const ref = useRef(null)

  let query = queryString.parse(props.location.search)

  const id = query && query.id ? Number(query.id) : 0
  const [position, setPosition] = useState([])
  const [status, setStatus] = useState([])
  const [source, setSource] = useState([])
  const [loader, setLoader] = useState(false)
  const [tab, setTab] = useState(1)
  const notify = () => {
    toast.success("Application Submitted Successfully", {
      position: "bottom-center",
      autoClose: 7000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    })
  }
  //const [file, setFile] = useState('');
  const countries = countryJson.getCountries().sort((a, b) => {
    if (a.name < b.name) return -1
    if (a.name > b.name) return 1
    return 0
  })
  const getStates = (countryCode) => {
    if (countryCode) {
      return countryJson.getStatesByShort(countryCode)
    }
    return []
  }
  const getCities = (countryCode, state) => {
    if (countryCode) {
      return countryJson.getCities(countryCode, state)
    }
    return []
  }

  const getDateFormate = (date) => {
    var dd = String(date.getDate()).padStart(2, "0")
    var mm = String(date.getMonth() + 1).padStart(2, "0") //January is 0!
    var yyyy = date.getFullYear()
    return { date: yyyy + "-" + mm + "-" + dd }
  }
  const handleSubmit = (e, values, resetForm) => {
    e.preventDefault()
    const formValues = values
    resetForm()
    setLoader(false)
    ref.current.continuousStart()
    const resumeDate = getDateFormate(formValues.resumeDate)
    const itemName = formValues.name
    const columnValues = JSON.stringify({
      name: formValues.name,
      status: formValues.status,
      date_cv_resume_submitted35: resumeDate,
      text: formValues.name,
      text27: formValues.country,
      text8: formValues.city,
      text6: formValues.state,
      text3: formValues.skype,
      text69: formValues.linkdin,
      text0: formValues.email,
      dropdown: formValues.source,
      text65: position.items && position.items[0] ? position.items[0].name : "",
    })

    const itemId = id
    const formData = new FormData()
    formData.append(
      "query",
      `mutation ($file: File!) {add_file_to_column (item_id:${itemId}, column_id: "files", file:$file) {id}}`
    )
    formData.append("variables[file]", formValues.resume)
    axios
      .post("https://api.monday.com/v2", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization:
            "eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjEzNzM2ODE4NywidWlkIjoxMzk0NzM2NywiaWFkIjoiMjAyMS0xMi0xNVQxNzowNDo1NC4yNjZaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6NjE5MDU1NiwicmduIjoidXNlMSJ9.OYbS9wZZWFZ6GdiimOmVeQOk0MJCDgHQVtlUxajJOYc",
        },
      })
      .then((response) => {
        if (response) {
          setLoader(true)
          ref.current.complete()
          notify()
          setTimeout(() => {
            navigate("/careers")
          }, 3000)
        }
      })

      .catch((err) => {
        notify()
      })
  }

  useEffect(() => {
    setLoader(false)
    ref.current.continuousStart()
    const client = new ApolloClient({
      link: new HttpLink({
        uri: "https://api.monday.com/v2",
        fetch,
        headers: {
          Authorization:
            "Bearer eyJhbGciOiJIUzI1NiJ9.eyJ0aWQiOjEzNzM2ODE4NywidWlkIjoxMzk0NzM2NywiaWFkIjoiMjAyMS0xMi0xNVQxNzowNDo1NC4yNjZaIiwicGVyIjoibWU6d3JpdGUiLCJhY3RpZCI6NjE5MDU1NiwicmduIjoidXNlMSJ9.OYbS9wZZWFZ6GdiimOmVeQOk0MJCDgHQVtlUxajJOYc",
          contentType: "multipart/form-data",
        },
      }),
      cache: new InMemoryCache(),
    })
    client
      .query({
        query: gql`
          query {
            items(ids: [ ${Number(query.id)}]) {
              name
              column_values {
                id
                text
                column {
                    title
                    type
                  }
              }
            }
          }
       `,
      })
      .then((result) => {
        setLoader(true)
        ref.current.complete()
        const position = result.data?.items?.length && result.data
        setPosition(position)
      })
    client
      .query({
        query: gql`
          query {
            boards(ids: 2042300520) {
              columns(ids: ["status", "dropdown"]) {
                settings_str
              }
            }
          }
        `,
      })
      .then((result) => {
        const columns = result.data.boards.length && result.data?.boards[0]
        let labels =
          columns.columns.length && JSON.parse(columns.columns[0].settings_str)
        let labels1 =
          columns.columns.length && JSON.parse(columns.columns[1].settings_str)
        labels = Object.values(labels.labels)
        labels = labels.map((item) => {
          return { label: item, value: item }
        })
        labels1 = labels1.labels.map((item) => {
          return { label: item.name, value: item.name }
        })
        setStatus(labels)
        setSource(labels1)
      })
  }, [])
  const { column_values, name } =
        position.items && position.items[0]
            ? position.items[0]
            : { column_values: {}, name: "" }
  let positionName,
    industry,
    date,
    location,
    jd,
    details,
    fun,
    recruiter,
    employmentStatus
  if (position && position.items && position.items[0]) {
    positionName = position.items[0].name
     const responseObjectArray = position.items[0].column_values; 
        const extractTextFromColumn = (responseObjectArray, columnTitle) => {
            const columnObj = responseObjectArray.find(obj => obj.column.title === columnTitle);
            return columnObj ? columnObj.text : undefined;
        };
        
        industry = extractTextFromColumn(responseObjectArray, "Industry");
        location = extractTextFromColumn(responseObjectArray, "Location");
        recruiter = extractTextFromColumn(responseObjectArray, "Recruiter");
        employmentStatus = extractTextFromColumn(responseObjectArray, "Employment Status");
        date = extractTextFromColumn(responseObjectArray, "Date Posted");
        jd = extractTextFromColumn(responseObjectArray, "Job Description");
        fun = extractTextFromColumn(responseObjectArray, "Function");

  }
  return (
    <>
      <LoadingBar color="#008001" ref={ref} />
      {!loader ? (
        <h1></h1>
      ) : (
        <Layout isOpen={true} positionDetail={true}>
          <ToastContainer
            position="bottom-center"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
          />
          {/* <Loader loaded={loader} options={options} className="spinner" /> */}
          {position && position.items && position.items[0] && (
            <div class="content-container career-container">
              <div class="container">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="career-head border-bottom">
                      <h3 class="job-title">{positionName}</h3>
                      <h6>{industry}</h6>
                      <ul class="career-icon-list d-flex flex-wrap">
                        <li>
                          <span class="icon">
                            <img src="/calendar-icon.svg" alt="" />
                          </span>
                          {moment(date).format("MMM D")}
                        </li>
                        <li>
                          <span class="icon">
                            <img src="/location-icon.svg" alt="" />
                          </span>
                          {location}
                        </li>
                        <li>
                          <span class="icon">
                            <img src="/briefcase.svg" alt="" />
                          </span>
                          {employmentStatus}
                        </li>
                        <li />
                      </ul>
                    </div>
                    <div class="career-body ">
                      {fun && fun !== null && fun !== undefined && (
                        <div class="career-text-block">
                          <h4>Function</h4>
                          <h6>{fun}</h6>
                        </div>
                      )}
                      {jd && jd !== null && jd !== undefined && (
                        <div class="career-text-block">
                          <h4>Details</h4>
                          <p class="para-custom">{jd}</p>
                        </div>
                      )}
                      {/* <div class="career-text-block">
                    <h4>Responsibilities</h4>
                    <ul>
                      <li>
                        Daily Client Calls (Skype & Zoom) to assist in the
                        facilitation of the ecommerce of portfolio assets
                      </li>
                      <li>Direct interaction with Clients</li>
                      <li>
                        Garner deep understanding of Seller & Buyer Motivations,
                        Needs, Objections
                      </li>
                      <li>
                        Cultivate new and nurture existing Client Relationships
                      </li>
                      <li>
                        Create a pipeline of active Buyers, Sellers & Listings
                      </li>
                      <li>
                        Forecast opportunities within primary & secondary
                        markets
                      </li>
                      <li>Conduct business development/research projects</li>
                      <li>
                        Document daily client interaction activity within
                        systems
                      </li>
                      <li>Work Experience highly preferred:</li>
                      <li>
                        Direct Sales Cycle Knowledge: Prospecting, Making
                        Contact, Qualifying Leads, Offerings, Handling
                        Objections, Closing & Nurturing.
                      </li>
                      <li>OCTG, Drill Pipe and Line Pipe</li>
                      <li>Oil and Gas Equipment Familiarity</li>
                      <li>Material Inventory Management and Inspections</li>
                      <li>Logistics, Customs, International Freight</li>
                      <li>Pricing, Negotiation</li>
                      <li>Managing Corporate Client Relationships</li>
                      <li>Commercial Insurance-Claims knowledge</li>
                      <li>Transportation-Vehicle/Equipment knowledge</li>
                    </ul>
                  </div>
                  <div class="career-text-block">
                    <h4>Requirements</h4>
                    <ul>
                      <li>Proficient in speaking and writing English.</li>
                      <li>
                        Bachelor’s degree or equivalent experience in a relevant
                        field
                      </li>
                      <li>
                        Access to computer with camera, microphone, speakers and
                        strong, reliable internet connection
                      </li>
                      <li>
                        B2B Exp in Business Development or Sales in the Oil &
                        Gas industry
                      </li>
                      <li>Market/Industry Research</li>
                    </ul>
                  </div> */}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="career-form-block border-top">
                      <h4>
                        Salvex has an awesome team of socially responsible
                        Consultants all over the world contributing to our
                        mission. We are delighted you are interested in sharing
                        your talent too! Please click on the DROP Box to share
                        your information with us.
                      </h4>
                    </div>
                  </div>
                  <div className="col-lg-2 mt-4 form-group">
                    <button
                      type="submit"
                      className=" btn btn-career btn btn-primary btn-sm 
                                    text-white pr-3 pl-3 nav-link"
                      onClick={(e) => {
                        navigate(`/apply-for-position?id=${query.id}`)
                      }}
                    >
                      Drop Box
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Layout>
      )}
    </>
  )
}
export default PositionDetail
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const validationSchema = Yup.object({
  name: Yup.string()
    .max(15, "Must be 15 characters or less")
    .required("Name is required"),
  email: Yup.string()
    .email("Must be a valid email")
    .max(255)
    .required("Email is required"),
  skype: Yup.string(),

  linkdin: Yup.string(),
  country: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
  resumeDate: Yup.date().required("Date is required"),
  resume: Yup.mixed().required("A file is required"),
  source: Yup.string().required("source required"),
})

let initialValues = {
  name: "",
  email: "",
  skype: "",
  linkdin: "",
  phone: "",
  country: "",
  state: "",
  city: "",
  // languges: [],
  // skills: [],
  status: "",
  resumeDate: new Date(),
  resume: "",
  source: "",
}
var options = {
  lines: 13,
  length: 20,
  width: 10,
  radius: 30,
  scale: 1.0,
  corners: 1,
  color: "#07bc0c",
  opacity: 0.25,
  rotate: 0,
  direction: 1,
  speed: 1,
  trail: 60,
  fps: 20,
  zIndex: 2e9,
  top: "50%",
  left: "50%",
  shadow: false,
  hwaccel: false,
  position: "absolute",
}
